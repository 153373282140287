import React from 'react';
import { Route, Switch } from 'react-router-dom';

import JobPostsPage from '../pages/JobPostsPage';
import JobPostPage from '../pages/JobPostPage';
import JobSchedulePage from '../pages/JobSchedulePage';

import { jobPostsRoutes } from './constants';

const JobPostsRoutes = () => {
	return (
		<Switch>
			<Route path={jobPostsRoutes.jobPostSchedule(':jobPostId')}>
				<JobSchedulePage />P
			</Route>

			<Route path={jobPostsRoutes.jobPostsCategory(':jobPostsCategory')} exact>
				<JobPostsPage />
			</Route>

			<Route path={jobPostsRoutes.jobPost(':jobPostId')} exact>
				<JobPostPage />
			</Route>

			<Route path={jobPostsRoutes.home} exact>
				<JobPostsPage />
			</Route>
		</Switch>
	);
};

export default JobPostsRoutes;
