import React from 'react';

import Text from '../../../../../corev2/Text';

import {
	StyledDocumentsListContainer,
	StyledDocumentsListWrapper,
	StyledTitle,
	StyledDocumentList,
	StyledDocument,
	StyledDocumentTitle,
	StyledDocumentDate,
	StyledDocumentRejectionNote,
} from './styles';

const DocumentsList = (props) => {
	const { title, documents } = props;

	return (
		<StyledDocumentsListContainer>
			<StyledDocumentsListWrapper>
				<StyledTitle>{title}</StyledTitle>

				<StyledDocumentList>
					{documents.map((document, index) => (
						<StyledDocument key={index}>
							<StyledDocumentTitle
								href={document.uploadedDocuments[0].document_url}
								target='_blank'
							>
								{document.document_name}
							</StyledDocumentTitle>
							<StyledDocumentDate>
								Uploaded on:{' '}
								{new Date(document.uploaded_date).toLocaleString('en-US', {
									month: 'long',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									hour12: true,
								})}
							</StyledDocumentDate>

							{document.expiration_date && (
								<StyledDocumentDate>
									Expriring on:{' '}
									{new Date(document.expiration_date).toLocaleString('en-US', {
										month: 'long',
										day: 'numeric',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
										hour12: true,
									})}
								</StyledDocumentDate>
							)}

							{document.rejection_notes && (
								<>
									<br />
									<StyledDocumentRejectionNote>
										REJECTION NOTES: {document.rejection_notes}
									</StyledDocumentRejectionNote>
								</>
							)}
						</StyledDocument>
					))}

					{documents.length === 0 && <Text>No documents found.</Text>}
				</StyledDocumentList>
			</StyledDocumentsListWrapper>
		</StyledDocumentsListContainer>
	);
};

export default DocumentsList;
